.ant-layout-content.main-content {
  background-color: $white;
}

.ant-layout-content.content {
  background-color: $white;
  // height: 100vh;
  padding-top: 1rem;
  padding-left: 90px;
  padding-right: 90px;
}

.ant-layout-header.landing-header {
  background-color: $transparent;
  border-color: black;
  border-style: solid;
}

@media only screen and (max-width: 600px) {
  .ant-layout-sider.session-sider {
    display: none;
  }
}

.ant-layout-sider.session-sider {
  background-image: url('./images/rectangle-17.png');
  background-repeat: no-repeat;
  background-size: cover;

  .sider-session-image:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: #01003a;
    opacity: 0.6;
  }
}

#side-menu {
  // min-width: 18vw !important;
  background-color: $dark-blue-2;
  font-family: 'Roboto';
  flex: none !important;
  max-width: 368px !important;
  min-width: 315px !important;
  width: 20% !important;

  .logo {
    // display: flex;
    // justify-content: center;
    margin-left: 3rem;
    margin-top: 55.5px;
    margin-bottom: 53.5px;
  }
  .small-logo {
    display: flex;
    justify-content: center;
    margin: 10px 5px 40px 5px;
  }

  .side-item {
    // height: 30%;
    background-color: $dark-blue-2;

    padding: 1.5rem 0;
    margin: 0px;
    font-size: 1rem;
    font-family: 'Roboto';
    color: $gray-2;
    a {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    svg {
      height: 1.5em;
      margin-left: 1rem;
      margin-right: 10px;
      // position: relative;
      vertical-align: middle;
    }

    p {
      display: inline-block;
      margin: 0;
      // position: relative;
      // vertical-align: middle;
    }
  }
  .side-item.ant-menu-item-selected {
    background-color: $white-transparent;
    border-radius: 4px;
    font-weight: 500;
    color: $dark-blue-1;

    // letter-spacing: 1px;
  }

  .sign-out-item {
    position: absolute;
    bottom: 0px;
  }
}

#header {
  background-color: $gray-1;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 90px;

  .site-name-2 {
    font-family: 'Roboto Slab';
    font-size: 1.75rem;
    font-weight: bold;
    color: $dark-blue-1;
    p {
      margin: 0;
    }
  }

  .account-info-2 {
    // height: 55px;
    color: $dark-gray-2;
    font-family: 'Roboto';
    font-size: 1.3125rem;
    display: flex;
    flex-direction: row;
    div {
      padding-right: 47px;
      display: flex;
      align-items: center;
      svg {
        height: 2.3rem;
      }
    }
    p {
      border-left: 1px solid $dark-blue-1;
      padding-left: 50px;
      margin: 0;
      line-height: 55px;
    }
  }
}
