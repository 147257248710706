$blue: #173f8a;
$white: #ffffff;
$white-transparent: #ffffff59;
$gray-1: #f5f8fa;
$gray-1-light: #f5f8fa70;
$gray-2: #dde8f1;
$dark-gray-1: #abb8c3;
$dark-gray-2: #52575a;
$dark-gray-2-light: #52575a22;
$pink: #e81687;
$pink-light: #e8168638;
$pink-dark: #ca217b;
$light-blue: #4292ff;
$light-blue-transparent: #4291ff11;
$dark-blue-1: #050358;
$dark-blue-2: #01003a;
$transparent: rgba(0, 0, 0, 0);
