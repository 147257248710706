/* Los estilos principales se encuentran en el archivo bank.scss*/
/* Estilos Adicionales */
.banks-list {
  #banks-head {
    .last-botton {
      margin-left: 10px;
    }

    .danger {
      background-color: $pink;
      border-color: $pink;
    }
  }
}

#bank-modal-body {
  .modal-form {
    .ant-form-item {
      margin: 5px auto;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.bank-office-head {
  span {
    font-size: 20px;
  }
  text-align: end;
}
