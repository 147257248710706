.sign-in-div {
  display: flex;
  flex-direction: column;

  .flit-logo {
    width: 320px;
    align-self: center;
    margin-top: 130px;
  }

  .sign-in-form {
    width: 320px;
    align-self: center;
    margin-top: 83px;
    .ant-tabs-nav {
      width: 100%;
    }

    .ant-tabs-tab {
      width: 50%;
      text-align: center;
      margin: 0;
    }

    .ant-form-item-label {
      z-index: 1;
      padding: 0;
      position: relative;
      top: 15px;
      left: 10px;
      p {
        background-color: $white;
      }
    }
    .ant-input {
      height: 40px;
      border-radius: 8px;
      border: 1.15px solid $dark-blue-1;
      background-color: white;
    }

    // .ant-row {
    //   position: relative;
    //   top: -15px;
    // }
    .ant-row:nth-child(2) {
      margin: 0;
    }

    .form-actions {
      .link {
        text-align: end;
        a {
          color: $dark-blue-1;
        }
      }

      .button {
        background-color: $dark-blue-1;
        border-radius: 4px;
        border: 0px;
      }
    }
  }
}

.password-forget {
  font-family: 'Roboto';
  padding: 50px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: $dark-blue-1;
    font-weight: 500;
    font-size: 1.5rem;
    padding-bottom: 10px;
  }

  form {
    width: 25%;
    min-width: 200px;
  }
  form > button {
    width: 100%;
    margin-bottom: 20px;
    background-color: $dark-blue-1;
    border: solid 0px black;
    color: $white;
  }
}
