.users-list {
  #users-head {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 1.5rem;
      color: $dark-blue-1;
      font-family: 'Roboto';
      font-weight: normal;
    }
    .ant-btn-primary {
      font-size: 1rem;
      height: 45px;
      border-radius: 4px;

      background-color: $dark-blue-1;
      font-family: 'Roboto';
    }
  }

  #user-main {
    thead,
    .ant-table-thead {
      .ant-table-column-sorters,
      .ant-table-cell {
        background-color: $white;
        color: $dark-gray-2;
        font-family: 'Roboto';
        font-size: 0.75rem;
      }
    }

    .ant-table-row {
      background-color: $gray-1;
      // border: 10px solid $white;
      // margin-bottom: 20px;
      td {
        border-bottom: 5px solid $white;
      }
      .ant-table-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 3rem;
        font-size: 0.9rem;
        font-family: 'Roboto';
        margin: auto;

        p {
          margin: 0;
        }

        .title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 270px;
        }

        .summary {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 200px;
        }

        #link {
          //   text-decoration: underline;
          color: $light-blue;
          cursor: pointer;
        }

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        svg {
          margin-top: 3px;
          cursor: pointer;
          width: 1.2rem;
          margin-left: 10px;
        }

        #link-disabled > svg {
          cursor: not-allowed;
        }
      }
    }
  }
}

#user-modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  button {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: 40px;
    padding: 5px 65px 10px 65px;
  }
}

#user-modal-body {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  padding-left: 75px;
  padding-right: 75px;
  font-family: 'Roboto';
  h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Roboto';
    color: $dark-blue-1;
    margin-bottom: 27px;
  }

  #email {
    color: $dark-blue-2;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  #roles {
    display: flex;
    margin-bottom: 20px;
    // justify-content: center;
    // align-items: center;
  }
}
