.home-page {
  #home-head {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 1.5rem;
      color: $dark-blue-1;
      font-family: 'Roboto';
      font-weight: normal;
    }
  }
}
