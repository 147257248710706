.financial-education-page {
  .finantial-head {
    margin-top: 2.5rem;
    font-family: 'Roboto';
    h1 {
      font-size: 1.5rem;
      color: $dark-blue-1;
      font-family: 'Roboto';
      font-weight: normal;
    }
  }

  .finantial-explanation {
    font-family: 'Roboto';

    h3 {
      margin-top: 1.4rem;
      color: $dark-blue-1;
    }

    .example {
      textarea {
        min-height: 150px;
        color: $light-blue;
        border: 1px solid $light-blue;
      }

      div.example-div {
        margin-top: 10px;
        min-height: 150px;
        border: solid 1px $dark-blue-1;
        padding: 3px;
      }
    }
  }

  #financial-article {
    width: 70%;
    margin-top: 10px;
    font-family: 'Roboto';
    h1 {
      font-size: 1.6rem;
    }
    h1,
    h2,
    h3 {
      // font-size: 3rem;
      color: $dark-blue-1;
      margin-top: 20px;
    }
  }
}

#articles-content {
  width: 60%;
  margin: 2rem auto;
  // margin-bottom: 10rem;
  // margin: 1rem 3rem;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  p {
    text-align: justify;
    text-justify: inter-word;
  }
  h1 {
    font-size: 1.5rem;
    color: $dark-blue-1;
    font-weight: normal;
  }

  .summary {
  }
  .date {
    font-weight: 500;
  }

  img.link {
    max-height: 400px;
    width: auto;
    height: auto;
  }

  .link {
    margin-top: 20px;
    font-size: 1.1rem;
  }

  .content {
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 20px;
  }
}
