.applications-list {
  #applications-head {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 1.5rem;
      color: $dark-blue-1;
      font-family: 'Roboto';
      font-weight: normal;
    }
    .ant-btn-primary {
      font-size: 1rem;
      height: 45px;
      border-radius: 4px;

      background-color: $dark-blue-1;
      font-family: 'Roboto';
    }
  }

  #application-main {
    thead,
    .ant-table-thead {
      .ant-table-column-sorters,
      .ant-table-cell {
        background-color: $white;
        color: $dark-gray-2;
        font-family: 'Roboto';
        font-size: 0.75rem;
      }
    }

    .ant-table-row {
      background-color: $gray-1;
      // border: 10px solid $white;
      // margin-bottom: 20px;
      td {
        border-bottom: 5px solid $white;
      }
      .ant-table-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 3rem;
        font-size: 0.9rem;
        font-family: 'Roboto';
        margin: auto;

        p {
          margin: 0;
        }

        .title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 270px;
        }

        .summary {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 200px;
        }

        #link {
          //   text-decoration: underline;
          color: $light-blue;
          cursor: pointer;
        }

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        svg {
          margin-top: 3px;
          cursor: pointer;
          width: 1.2rem;
          margin-left: 10px;
        }

        #link-disabled > svg {
          cursor: not-allowed;
        }
      }
    }
  }
}

.application-show {
  .application-header {
    display: flex;
    flex-direction: column;

    .labels {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      // background-color: red;
      margin-top: 18px;
      margin-bottom: -30px;
      .ant-tag {
        font-family: 'Roboto';
        font-size: 0.98rem;
        padding: 3px 15px;
        color: $dark-blue-1;
        border-radius: 2px;
      }
      .bold {
        border-radius: 10px;
        font-weight: 500;
      }
    }

    .application-type {
      margin-bottom: 12px;
      font-weight: 300;
      font-size: 0.8rem;
    }

    .main-info {
      display: flex;
      @media all and (max-width: 1200px) {
        margin-top: 10px;
        flex-direction: column;
      }

      justify-content: space-between;
      font-size: 1.01rem;

      .title {
        color: $dark-blue-1;
        display: flex;
        align-items: center;
        .name {
          font-weight: 500;
          font-size: 1.35rem;
          padding-right: 10px;
          border-right: solid 1px $dark-blue-1;
        }

        .userName {
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          .location {
            font-size: 0.7rem;
          }
        }
        .createdAt {
          height: 100%;
          // display: flex;
          // flex-direction: row;
          // justify-content: start;
        }
      }
      .extra {
        display: flex;
        align-items: center;
        .time-limit {
          padding: 8px 20px;
          background-color: $light-blue-transparent;
          border-radius: 5px;
          border: solid 1px $light-blue;
          color: $dark-blue-1;
          .bold {
            font-weight: 500;
            padding-right: 10px;
          }
          .remaining {
            padding-left: 10px;
            border-left: solid 1px $dark-blue-1;
          }
        }
        .back a {
          margin-left: 18px;
          color: $pink !important;
        }
      }
    }
  }

  #app-data {
    padding: 10px;
    margin-top: 35px;
    font-family: 'Roboto';

    .main-info {
      padding: 28px 0;
      border-radius: 5px;
      background: linear-gradient($dark-blue-2, $dark-blue-1);
      color: $white;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.95rem;
      justify-content: space-evenly;
      div:not(:last-child) {
        border-right: solid 1px $white;
      }

      div.no-border-right {
        border-right: none;
      }

      .main-info-container-1,
      .main-info-container-2 {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      .main-info-container-2 {
        margin-top: 20px;
      }

      div {
        // padding: 0px 10px;
        flex-grow: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title {
          font-weight: 500;
        }

        .small-margin {
          margin-top: 25px;
        }
      }

      .container-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .container-data1 {
          margin-top: 5px;
          border-right: none;
        }

        .container-data2 {
          margin-top: 5px;
        }
      }
    }

    .main-info.additional-property {
      color: $dark-blue-1;
      border: 2px solid $dark-blue-1;
      background: transparent;
      background-color: $gray-1;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;

      div {
        flex: 25%;
        border-right: none;
      }

      .mini-content {
        flex: 100%;

        @media only screen and (min-width: 992px) {
          flex: 25%;
        }
      }

      .insurance-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @media only screen and (min-width: 992px) {
          margin-bottom: 10px;
        }

        .insurance-item {
          display: flex;
          flex-direction: row;

          .no-selected-icon {
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-bottom: 2px;
            margin-right: 2px;

            @media only screen and (min-width: 992px) {
              margin-right: 0;
            }
          }

          @media only screen and (min-width: 992px) {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .secondary-info {
      -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      display: flex;
      flex-direction: column;
      padding: 20px 61px;
      margin-top: 25px;
      margin-bottom: 25px;
      background-color: $gray-1-light;
      border-radius: 5px;

      .holder-bottom {
        // Para borde inferior
        border-bottom: solid 3px transparent;
        border-image: linear-gradient(to left, $pink, $white);
        border-image-slice: 1;
        width: 80%;
        // margin-top: -5px;
      }
      .holder {
        border-top: solid 3px transparent;
        border-image: linear-gradient(to right, $pink, $white);
        border-image-slice: 1;
        // border-bottom: solid 3px transparent;
        // border-image: linear-gradient(to left, $pink, $white);
        width: 80%;
        .ant-descriptions-row:not(:last-child) {
          border-bottom: solid 1px $dark-gray-2-light;
        }

        .ant-descriptions-item {
          padding-top: 6px;
          padding-bottom: 6px;
          .ant-descriptions-item-content {
            font-size: 1.1rem;
            color: $dark-blue-1;
            font-weight: 500;

            div {
              display: flex;
              flex-direction: row;
              span {
                svg {
                  margin-right: 5px;
                }
                padding-right: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }
          .ant-descriptions-item-label {
            font-size: 1.1rem;
            font-weight: 400;
            min-width: 30%;
            color: $dark-blue-2;
          }
        }
      }

      .part:not(:last-child) {
        border-bottom: solid 1px $dark-gray-2-light;
      }
      .part {
        display: flex;
        flex-direction: column;
        color: $dark-blue-1;
        padding-bottom: 40px;
        padding-top: 22px;
        h2.part-title {
          color: $dark-blue-1;
          margin-bottom: 15px;
          font-weight: bold;
        }
        .container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div.secondLast {
            flex-grow: 1;
          }
          div.last {
            padding-right: 0px;
            min-width: 250px;

            color: $pink;
            font-weight: 600;
            font-size: 1.35rem;
            font-stretch: condensed;
            .title {
              color: $dark-gray-1;
              font-weight: normal;
              font-size: 0.95rem;
              font-stretch: normal;
            }
          }
          div {
            display: flex;
            flex-direction: column;
            padding-right: 90px;
            font-size: 1.1rem;

            .title {
              color: $dark-gray-1;
              font-size: 0.95rem;
            }
          }
        }
      }
    }
  }
  #files-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    .files {
      width: 100%;
      min-height: 200px;
      // background-color: $gray-1-light;
      padding: 30px;
      font-family: 'Roboto';

      #userFiles {
        display: flex;
        flex-direction: column;
        span {
          // border-bottom: solid 1px $dark-gray-1;
          padding-top: 2px;
        }
      }

      h4 {
        font-weight: 500;
        color: $dark-blue-2;
      }
      a {
        display: flex;
        align-items: center;
        svg {
          height: 1.1rem;
        }
      }
    }
  }

  #taken-answer {
    padding: 10px;
    .first {
      background: linear-gradient($dark-blue-2, $dark-blue-1);
      padding: 28px 60px;
      border-radius: 5px;
      color: white;
      h2 {
        font-family: 'Roboto slab';
        color: white;
        padding-bottom: 5px;
        border-bottom: solid 1px $white-transparent;
      }

      .data {
        display: flex;
        flex-direction: row;
        width: 100%;
        div {
          margin-right: 10%;
          display: flex;
          flex-direction: column;

          span.title {
            font-weight: 500;
          }
        }
      }
    }
    .second {
      z-index: 2;
      -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      background-color: $gray-1-light;
      margin-top: 20px;
      padding: 28px 60px;
      border-radius: 5px;
      color: $dark-blue-1;
      h2 {
        font-family: 'Roboto slab';
        color: $dark-blue-1;
        padding-bottom: 5px;
        border-bottom: solid 1px $dark-gray-2-light;
      }

      .data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        div {
          display: flex;
          flex-direction: column;

          span.title {
            font-weight: 500;
          }
        }
      }
    }

    .files {
      cursor: pointer;
      background-color: #e8168720;
      border: solid 1px $pink;
      border-radius: 5px;
      width: 60%;
      margin: auto;
      margin-top: 30px;
      padding: 24px 121px;
      display: flex;
      justify-content: center;
      span {
        color: $pink;
        svg {
          fill: $pink;
        }
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    .buttons {
      margin: auto;
      margin-top: 30px;
      padding-top: 30px;
      border-top: solid 1px $dark-gray-2-light;
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        margin: 0px 7px;
        color: $dark-blue-2;
        font-family: 'Roboto';
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px $dark-blue-2;
        // padding: 10px;
        height: 40px;
        width: 190px;
      }
    }
    button.send {
      background-color: $dark-blue-1;
      color: $white;
    }
    .reject > button,
    button.reject {
      color: $pink-dark;
      border: solid 1px $pink;
      // background-color: $pink-light;
    }
  }
}
