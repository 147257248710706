@import '~antd/dist/antd.css';

@import 'colors';
@import 'fonts';

@import './../components/Layout/layout.scss';
@import './../components/Landing/landing.scss';
@import './../components/Home/home.scss';
@import './../components/Session/session.scss';
@import './../components/Navigation/navigation.scss';
@import './../components/Bank/bank.scss';
@import './../components//BankOffices/bankOffice.scss';
@import './../components/Articles/articles.scss';
@import './../components/Users/users.scss';
@import './../components/Applications/applications.scss';
@import './../components/FinantialEducation/index.scss';
@import './../components/Metrics/index.scss';

.font-152 {
  font-size: 12vw;
}

.font-60 {
  font-size: 4.5vw;
}

.font-50 {
  font-size: 4vw;
}

.font-28 {
  font-size: 2vw;
}

.font-21 {
  font-size: 1.7vw;
}

.font-18 {
  font-size: 1.4vw;
}

.font-14 {
  font-size: 1.1vw;
}

.font-12 {
  font-size: 0.9vw !important;
}

.font-9 {
  font-size: 0.7vw;
}

// MODALS

.ant-modal-content {
  width: 600px;
  border-radius: 8px;
}
.ant-modal-body {
  padding: 0px;
}

.ant-modal-footer {
  border-top: 0px solid #f0f0f0 !important;
  padding: 0px;
}
