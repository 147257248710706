// Index Component
.metrics-container {
  margin-top: 2.5rem;
  font-family: 'Roboto';

  h1 {
    font-size: 1.5rem;
    color: $dark-blue-1;
    font-weight: normal;
    text-transform: capitalize;
  }

  h2 {
    color: $dark-blue-1;
  }

  span {
    font-weight: bold;
    color: $dark-blue-1;
  }

  .metrics-content {
    border: 2px solid $dark-blue-1;
    border-radius: 5px;
    background-color: $gray-1;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .metrics-boxes {
      text-align: center;
    }
  }
}

.loading {
  text-align: center !important;
}
