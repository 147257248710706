.articles-list {
  #articles-head {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 1.5rem;
      color: $dark-blue-1;
      font-family: 'Roboto';
      font-weight: normal;
    }
    .ant-btn-primary {
      font-size: 1rem;
      height: 45px;
      border-radius: 4px;

      background-color: $dark-blue-1;
      font-family: 'Roboto';
    }
  }

  #articles-main-table {
    thead,
    .ant-table-thead {
      .ant-table-column-sorters,
      .ant-table-cell {
        background-color: $white;
        color: $dark-gray-2;
        font-family: 'Roboto';
        font-size: 0.75rem;
      }
    }

    .ant-table-row {
      background-color: $gray-1;
      // border: 10px solid $white;
      // margin-bottom: 20px;
      td {
        border-bottom: 5px solid $white;
      }
      .ant-table-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 3rem;
        font-size: 0.9rem;
        font-family: 'Roboto';
        margin: auto;

        p {
          margin: 0;
        }

        .title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 270px;
        }

        .summary {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 200px;
        }

        #link {
          //   text-decoration: underline;
          color: $light-blue;
          cursor: pointer;
        }

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        svg {
          margin-top: 3px;
          cursor: pointer;
          width: 1.2rem;
          margin-left: 10px;
        }

        #link-disabled > svg {
          cursor: not-allowed;
        }
      }
    }
  }
}

#articles-content {
  margin-top: 2.5rem;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.5rem;
    color: $dark-blue-1;
    font-weight: normal;
  }

  .summary {
  }
  .date {
    font-weight: 500;
  }

  img {
    max-height: 350px;
    width: auto;
    height: auto;
  }

  .link {
    margin-top: 20px;
    font-size: 1.1rem;
  }

  .content {
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 20px;
  }
}

#article-modal-body {
  //   padding-top: 45px;
  //   padding-left: 75px;
  //   padding-right: 75px;
  padding: 2rem 5rem 2rem 5rem;
  font-family: 'Roboto';
  h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Roboto';
    color: $dark-blue-1;
    margin-bottom: 27px;
  }
  .modal-form {
    button {
      font-size: 18px;
      font-family: 'Roboto';
      font-weight: 500;
      background-color: $light-blue;
      border-radius: 8px;
      height: 40px;
      padding: 5px 65px 10px 65px;
    }

    .button-holder > .ant-col > .ant-form-item-control-input {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      //   background-color: red;
      justify-content: center;
    }

    .ant-form-item {
      margin: 0px;
      // padding-right: 20px;
    }
    .ant-form-item-label {
      padding: 0;
      color: $dark-blue-2;
      font-family: 'Roboto';
      font-size: 14px;
    }

    input,
    textarea {
      margin: 0px;
      height: 42px;
      border-radius: 8px;
      background-color: $gray-1;
      border: 0px;
      // border: 2px solid $light-blue;
    }
    textarea {
      height: 150px;
    }

    input:focus,
    textarea:focus {
      background-color: #4292ff51; // Lightblue con opacity
      color: $light-blue;
      box-shadow: none;
      border: 1px solid $light-blue;
      // opacity: 0.1;
    }
  }
}
