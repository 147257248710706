.menu {
  width: 100%;
  display: flex;

  .navBar-right {
    align-self: center;
    margin-right: 0;
    margin-left: auto;
  }
}

.navBar-button-right {
  align-self: center;
  margin-right: 0;
  margin-left: auto;
}
